//这个function是在服务器不支持权限配置的时候，为了前端结构不做大的修改，用于作为默认权限。
let functions = [
    {
        title: '查看',
        name: 'Query',
        permit: true,
        key: 'TestQuery',
        url: null,
    },
    {
        title: '新增',
        name: 'New',
        permit: true,
        key: 'TestNew',
        url: null,
    },
    {
        title: '编辑',
        key: 'TestEdit',
        permit: true,
        name: 'Edit',
        url: null,
    },
    {
        title: '删除',
        name: 'Delete',
        permit: true,
        key: 'TestDelete',
        url: null,
    }
];

//服务端有权限配置，填{}，代码执行时会被替换。否则把上面的functions填入作为默认权限
//同时leftMenu/Index.js里  componentDidMount中menuData数据不再需要checkPower
//配置了 openType:activeFirst 的重新打开时会先判断是否已经存在了，如果存在，则直接激活菜单。

//左侧导航栏配置 type:1:父栏(用于展开) 2:子栏(可跳转，需配置url)
module.exports = [
    {
        functions:functions,
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'GoodsManage',
        index:20,
        name:'商品管理',
        parentId:null,
        type:1,
        url:null,
    },
    {
        functions:functions,
        icon:null,
        id:'GoodsList',
        index:1,
        name:'商品列表',
        parentId:'GoodsManage',
        type:2,
        url:'GoodsList',
    },
    {
        functions:functions,
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'ShopManage',
        index:50,
        name:'店铺管理',
        parentId:null,
        type:1,
        url:null,
    },
    {
        functions:functions,
        icon:null,
        id:'ShopDetail',
        index:1,
        name:'我的店铺',
        parentId:'ShopManage',
        type:2,
        url:'ShopDetail',
    },

    {
        functions:functions,
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'MessageManage',
        index:60,
        name:'消息管理',
        parentId:null,
        type:1,
        url:null,
    },
    {
        functions:functions,
        icon:null,
        id:'MyMessage',
        index:1,
        name:'我的消息',
        parentId:'MessageManage',
        type:2,
        url:'MyMessage',
        openType: "activeFirst",
    },
];
