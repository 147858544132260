import React from 'react';
import SingleIMPage from "./SingleIMPage";

export default class MyMessage extends React.Component{
  constructor(props) {
    super(props);

    this.state = {

    }

  }

  render(){
    return <SingleIMPage/>
  }
}