import React from "react";
import less from "./GoodsManage.less";
import {Spin,Button,Modal,Form,Input,InputNumber,message} from "antd";
import APILXD from "../../../http/APILXD";
import HttpTool from "../../../tool/HttpTool";
import UploadFile from "../../../components/upLoad/UploadFile";
import {debounce} from "lodash";

export default class AddFoodItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      upDate: 0,
      defaultData:{},
      loading: false,
      nameValidateStatus: null,
      nameHelp: null
    }

    this.id = this.props.post.id;

    this.imgMaps = {};

    this.validateNameAction = debounce((name)=>{
      this.validateName(name)
    },800)
  }

  componentDidMount(){
    if(this.id){
      this.loadData(this.id);
    }
  }

  //请求数据
  loadData(id){
    if(!id){
      return
    }

    let param = {
      _id:this.id,
    };
    let successCB = (code,msg,json,option)=>{

      json.imgs?.forEach((url)=>{
        this.imgMaps[url] = true;
      })

      this.setState({
        loading:false,
        defaultData:json,
        upDate:this.state.upDate+1,
      });
    };
    let failureCB = (code,msg)=>{
      this.setState({loading:false},()=>{
        message.error(msg);
      });
    };

    this.setState({loading:true},()=>{
      HttpTool.post(APILXD.getGood,successCB,failureCB,param);
    });
  }

  //验证名称
  validateName(name){
    if(name){
      this.setState({
        nameValidateStatus: "validating",
        nameHelp: null
      })

      HttpTool.post(APILXD.checkGoodExist,(code,msg,data)=>{
        if(data.exist){
          this.setState({
            nameValidateStatus: "error",
            nameHelp: "菜名已经存在"
          })
        }else{
          this.setState({
            nameValidateStatus: "success",
            nameHelp: null
          })
        }
      },(code,msg)=>{
        message.warn(msg)
        this.setState({
          nameValidateStatus: "warning",
          nameHelp: "校验出错"
        })
      },{
        name: name
      });
    }
  }

  //点击创建完成按钮
  clickAddBtn(){

    this.baseMsgLayout.validateFields().then((values)=>{
      console.log(values)
      let delFiles = [];
      Object.keys(this.imgMaps)?.forEach((url)=>{
        if(!values.imgs.includes(url)){
          delFiles.push(url)
        }
      })


      let param = {
        name:values.name,
        price:values.price,
        imgs: values.imgs,
        delFiles: delFiles
      };

      if(this.id){
        param._id = this.id;
      }

      this.doSubmit(param);
    }).catch((errorInfo)=>{

    })
  }

  //提交数据
  doSubmit(data){
    let param = data;
    log(data)
    let successCB = (code,msg,json,option)=>{
      this.setState({loading:false},()=>{
        message.success('操作成功');
        this.imgMaps  = {};
        data.imgs?.forEach((url)=>{
          this.imgMaps[url]  = true;
        })
        this.props.openTab(this.props.post.parent);
        this.props.closeTab(this.props.targetKey);
      });
    };
    let failureCB = (code,msg)=>{
      this.setState({loading:false},()=>{
        message.error(msg);
      });
    };

    this.setState({loading:true});
    let url = this.id?APILXD.editGood:APILXD.addGood;
    HttpTool.post(url,successCB,failureCB,param);
  }

  render(){
    let {loading,defaultData,nameValidateStatus,nameHelp} = this.state;

    return <div className={less.fullPage}>
      {
        loading
          ?<div className={less.layerDiv}><Spin size={"large"}/></div>
          :null
      }
      <div className={less.content}>
        <div className={less.containerTitle}>{this.id?"编辑商品":"创建商品"}</div>
        <div className={less.formContainer}>
          <Form
            key={'layout'+this.state.upDate}
            ref={(ref)=>{
              this.baseMsgLayout = ref;
            }}
            initialValues={defaultData}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
          >
            <Form.Item
              validateStatus={nameValidateStatus}
              help={nameHelp}
              hasFeedback={true}
              label={"菜品名称"}
              name={"name"}
              rules={[{required: true}]}
              normalize={(value, prevValue, prevValues) => {
                this.validateNameAction(value)
                return value
              }}
            >
              <Input
                maxLength={30}
              />
            </Form.Item>
            <Form.Item
              label={"价格"}
              name={"price"}
              rules={[{required: true}]}
            >
              <InputNumber
                style={{width: "100%"}}
                min={0}
                max={100000000}
                step={1}
                precision={2}
                prefix="￥"
              />
            </Form.Item>
            <Form.Item
              label={"菜品图片"}
              name={"imgs"}
              rules={[{required: true}]}
              normalize={(value, prevValue, prevValues) => {
                return value?.map((each)=>{
                  this.imgMaps[each.url] = true;

                  return each.url
                })
              }}
            >
              <UploadFile
                accept={"image/*"}
                data={{
                  option: {
                    size: 5,
                    defaultValue: defaultData.imgs?.map((url)=>{
                      return {url}
                    }) || null
                  }
                }}
              />
            </Form.Item>
          </Form>
        </div>
        <div className={less.footer}>
          <Button
            size={"large"}
            style={{width: 120}}
            onClick={()=>{
              Modal.confirm({
                title: "提示",
                content: "取消创建将不会保留已经填写的内容，是否确定取消？",
                cancelText: "再想想",
                onOk: ()=>{
                  this.props.openTab(this.props.post.parent);
                  this.props.closeTab(this.props.targetKey);
                }
              })
            }}
          >取消</Button>
          <Button
            size={"large"}
            type={"primary"}
            style={{width: 120}}
            onClick={()=>{
              this.clickAddBtn()
            }}
          >完成</Button>
        </div>
      </div>
    </div>
  }
}