import './___.js'
import React, {Component} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {message, ConfigProvider} from "antd";
import CookieHelp from "./tool/CookieHelp";
import HttpTool from "./tool/HttpTool";
import Config from './config/config';
import routes from "./routes";
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
// import LXDHelp from "./main/help/LXDHelp";

//配置
moment.locale('zh-cn');

CookieHelp.userCookieKey = Config.projectUniqueName;
HttpTool.authCookieName = Config.projectUniqueName + "_AUTH";
HttpTool.timeout = Config.requestTimeout;
HttpTool.setEncrypt(Config.publicKey);

message.config({
    top: 200,
});

window["_NOENCRYPT"] = JSON.stringify(['/base-usercenter/']);
window["_CONCAT_API"] = '/api';

//加载外部js
// LXDHelp.loadScript("/js/aliyun-oss-sdk-6.1.0.min.js");
// LXDHelp.loadScript("/js/jquery.min.js");

ReactDOM.render(
    <ConfigProvider
        locale={zhCN}
    >
        <BrowserRouter>
            {routes}
        </BrowserRouter>
    </ConfigProvider>,
    document.getElementById("root")
);