import React, {useState, useEffect, forwardRef,useImperativeHandle,useRef} from "react";
import less from "./ShopDetail.less";
import {Button, Row, Col, Input} from "antd";
import UploadFile from "../../../components/upLoad/UploadFile";

export default forwardRef((props,ref)=> {
  const [albumList, setAlbumList] = useState(props.value || [])
  const imgMaps = useRef({});

  useImperativeHandle(ref,()=>{
    return {
      getAlbumList
    }
  })

  useEffect(()=>{
    props.value?.forEach((eachAlbum)=>{
      eachAlbum?.imgs?.forEach((url)=>{
        imgMaps.current[url] = true;
      })
    })
  },[props.value])

  const getAlbumList = ()=>{
    let error;

    let delImgs = [];
    let newImgs = {}

    if(albumList?.length){
      albumList.forEach((eachAlbum)=>{
        if(!eachAlbum.name){
          error = "存在未填写的类别名称";
        }
        if(!eachAlbum.imgs?.length){
          error = "存在未上传的类别图片";
        }else{
          eachAlbum.imgs.forEach((url)=>{
            newImgs[url] = true
          })
        }
      })
    }

    Object.keys(imgMaps.current).forEach((url)=>{
      if(!newImgs[url]){
        delImgs.push(url)
      }
    })

    return [error, albumList, delImgs]
  }

  return <div>
    {
      albumList?.map((eachAlbum,index)=>{
        return <div
          key={"a_" + index}
        >
          <Row>
            <Col span={4} className={less.label}>类别：</Col>
            <Col span={12}>
              <Input
                value={eachAlbum.name}
                placeholder={"填写类别名称"}
                maxLength={10}
                onChange={(e)=>{
                  let newList = _.cloneDeep(albumList)
                  newList[index].name = e.target.value
                  setAlbumList(newList)
                }}
              />
            </Col>
            <Col span={4}>
              <Button
                type={"primary"}
                danger={true}
                ghost={true}
                onClick={()=>{
                  let newList = albumList.slice(0,index).concat(albumList.slice(index+1))
                  setAlbumList(newList)
                }}
              >删除类别</Button>
            </Col>
          </Row>
          <Row>
            <Col span={4} className={less.label}>图片：</Col>
            <Col span={12}>
              <UploadFile
                accept={"image/*"}
                data={{
                  option: {
                    size: 20,
                    defaultValue: eachAlbum.imgs?.map((url)=>{
                      return {url}
                    }) || null
                  }
                }}
                onChange={(arr)=>{
                  let newList = _.cloneDeep(albumList)
                  newList[index].imgs = arr.map((each)=>{
                    imgMaps.current[each.url] = true
                    return each.url
                  })
                  setAlbumList(newList)
                }}
              />
            </Col>
          </Row>
        </div>
      })
    }
    {
      albumList?.length<5
        ?<Button
          type={"primary"}
          size={"large"}
          style={{width: "200px", display: "block", margin: "20px auto"}}
          onClick={()=>{
            let newList = _.cloneDeep(albumList)
            newList.push({
              name: null,
              imgs: [],
            })
            setAlbumList(newList)
          }}
        >+ 新增类别</Button>
        :null
    }
  </div>
})