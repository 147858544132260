export default {
  publicKey:
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCF3cbHgviK4mr3++zjXsDKq9v5\n" +
    "GYW4jbPsbhUh69CVzEuqj8Xl0A/9XQPDxkofW1MnxtlaLt7QMsLRZBOyGEvla09n\n" +
    "Fb1ZaW6an6bTAoLaQOnHRnLENxItU9GCNKU/638czmZoxCOjfm/P5KSo0n2PdkFZ\n" +
    "6DQTwC5Mwz7X3Gau0QIDAQAB\n" +
    "-----END PUBLIC KEY-----", //HttpTools 加密公钥；
  projectUniqueName: "PPS_BSS_ADMIN",//项目cookies存储用
  requestTimeout: 60000,  //接口请求超时(ms)
  // YXAppKey: "fd80b952ba8b29f7c8e4b275f00dd47e",//测试环境
  YXAppKey: "bae8646fca850df5e1cac7f5aa0df4d2",//正式环境
};
