import React from 'react';
import {
  Provider,
  ConversationContainer,
  ChatContainer,
} from '@xkit-yx/im-kit-ui';
import '@xkit-yx/im-kit-ui/es/style'
import config from "../../../config/config";
import CookieHelp from "../../../tool/CookieHelp";
import {Result} from "antd";
import less from "./MyMessage.less";

export default class SingleIMPage extends React.Component{
  constructor(props) {
    super(props);

    this.state = {

    }

    let userInfo = CookieHelp.getUserInfo() || {};

    this.initOptions = {
      appkey: config.YXAppKey,  // 传入您的App Key
      account: userInfo.accid, // 传入您的云信 IM 账号
      token: userInfo.accToken,   // 传入您的 Token
      debugLevel: 'debug', // 设置日志level
      // 参考 NIM SDK 的 initOptions
    }
  }

  componentWillUnmount() {
    console.log("销毁IM了！")
  }

  render(){
    if(!this.initOptions.account || !this.initOptions.token){
      return <Result
        status={500}
        title="无法使用消息服务"
        subTitle="您的账号不支持使用消息服务，请联系管理员。"
      />
    }

    return <Provider initOptions={this.initOptions} sdkVersion={1}>
      <div className={less.mainPage}>
        <div className={less.conversationBox}>
          <ConversationContainer
            renderSessionListEmpty={()=>{
              return <div className={less.listEmptyView}>
                <img style={{width: "200px"}} src={require("../../../images/im/list_empty.png")}/>
                <div>暂无会话记录</div>
              </div>
            }}
          />
        </div>
        <div className={less.chatBox}>
          <ChatContainer
            renderEmpty={()=>{
              return <div className={less.listEmptyView}>
                <img style={{width: "500px"}} src={require("../../../images/im/welcome.png")}/>
              </div>
            }}
          />
        </div>
      </div>
    </Provider>
  }
}