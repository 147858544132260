import React from 'react'
import { Route } from "react-router-dom";

import App from './main/App.js';
let routes = (<Route path="/">
    <App/>
</Route>);

export default routes;

