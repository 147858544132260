let APILXD = {
    /**
     * 通用
     */
    //上传文件
    upLoad: '/common/upload',
    //获取上传文件的token(阿里，七牛)
    getUploadToken: '/merchant/common/getUploadToken',
    //获取地理编码
    getGeoCode: '/merchant/common/getGeoCode',


    /**
     * 账号
     */
    //登陆
    login: '/merchant/merchant/login',
    //获取密码随机串
    getRandSalt: '/merchant/merchant/getRandSalt',
    //查询账号详情
    getMyinfo: '/merchant/merchant/getMyinfo',
    //退出登陆
    logoutAdmin: '/merchant/merchant/logoutMerchant',
    //修改密码
    modfiyPasswd: '/merchant/merchant/modfiyPasswd',
    //修改密码
    modfiyName: '/merchant/merchant/modfiyName',


    /**
     * 店铺
     */
    editShop: '/merchant/shop/editShop',
    setShopStatus: '/merchant/shop/setShopStatus',
    getShop: '/merchant/shop/getShop',

    /**
     * 商品
     */
    checkGoodExist: '/merchant/goods/checkGoodExist',
    addGood: '/merchant/goods/addGood',
    editGood: '/merchant/goods/editGood',
    delGood: '/merchant/goods/delGood',
    getGoodList: '/merchant/goods/getGoodList',
    getGood: '/merchant/goods/getGood',

    /**
     * 行业
     */
    getMerchantIndustry: '/merchant/industry/getMerchantIndustry'
};

module.exports = APILXD;
