import React, {Component} from 'react';

/** 首页 **/
import IndexPage from './indexPage/index';

//商品管理
import GoodsList from '../main/navigationPages/goodsManage/GoodsList';
import AddFoodItem from '../main/navigationPages/goodsManage/AddFoodItem';

//店铺
import ShopDetail from '../main/navigationPages/shopManage/ShopDetail';

//消息
import MyMessage from "../main/navigationPages/messageManage/MyMessage";

export default {
    getView(props) {
        return [
            {
                path: "IndexPage",
                component: <IndexPage {...props} />
            },
            {
                path: "GoodsList",
                component: <GoodsList {...props} />
            },
            {
                path: "AddFoodItem",
                component: <AddFoodItem {...props} />
            },
            {
                path: "ShopDetail",
                component: <ShopDetail {...props} />
            },
            {
                path: "MyMessage",
                component: <MyMessage {...props} />
            },
        ]
    }
};
