import React, {Component} from 'react';
import {Layout,Modal,message} from 'antd';
import Login from './login/Login.js';
import Index from './leftMenu/Index.js';
import css from './App.less';
import APILXD from '../http/APILXD.js';
import HttpTool from "../tool/HttpTool";
import CookieHelp from "../tool/CookieHelp";
const { Content } = Layout;


class page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upData: 0,
            userInfo: CookieHelp.getUserInfo()
        };
    }

    componentDidMount() {
        if(this.state.userInfo){
            this.toRequestUserInfo(this.state.userInfo.info);
        }
    }

    componentWillMount() {
        HttpTool.setSpecialCodeEvent((code)=>{
            if(code===-420){
                CookieHelp.clearUserInfo()
                window.location.reload();
                return false;
            }else if(code === -421){
                CookieHelp.clearUserInfo();
                HttpTool.clearAuthHeader();
                this.setState({
                    userInfo: null,
                    upData: this.state.upData + 1,
                })

                Modal.error({
                    title: "提示",
                    content: "您的账号已被禁用"
                })

                return false;
            }else{
                return true;
            }
        });
    }


    _logout() {
        this.logoutAction(()=>{
            CookieHelp.clearUserInfo();
            HttpTool.clearAuthHeader();
            this.setState({
                userInfo: null,
                upData: this.state.upData + 1,
            })
        })

    }
    /**
     * 登出
     */
    logoutAction(cb) {
        HttpTool.delete(APILXD.logoutAdmin,
          (code, msg, json, option) => {
              cb();
          },
          (code, msg, option) => {
              cb();
          }, {}
        )
    }

    //尝试请求后台，判断是否已经登陆失效
    toRequestUserInfo(info){
        if(!info || !info.id){
            CookieHelp.clearUserInfo();
            this.setState({
                userInfo: null,
                upData: this.state.upData + 1,
            })
            HttpTool.clearAuthHeader()
        }

        //如果已经登陆失效，则请求会返回420状态码，执行登出逻辑。否则设置权限。
        HttpTool.post(APILXD.getMyinfo,(code,msg,json)=>{
            this.setState({
                upData:this.state.upData+1,
            })
        },(code,msg)=>{
            Modal.error({
                title: "提示",
                content: msg
            })
        },{_id:info.id});
    }

    render() {
        //判断,是否登录
        var main = null;

        if (!this.state.userInfo) {
            //没登录.别进
            main = <Login login={(userInfo,isForget) => {
                CookieHelp.saveUserInfo(userInfo,isForget);
                HttpTool.setAuthHeader({
                    Authorization:userInfo.accessToken,
                    //os: "web_0.1.0"
                })

                this.setState({
                    userInfo: userInfo,
                    upData:this.state.upData+1,
                },()=>{


                })
            }
            } />;
        } else {
            main = (
              <div className={css.main_mask} >
                  <div className={css.main_mask2}>
                      <Content className={css.content}>
                          <Index
                            key={this.state.upData}
                            logout={()=>this._logout()}
                            action={(obj) => {
                                this.openTabObj = obj;
                            }} />
                          {this.props.children}
                      </Content>
                  </div>
              </div>);
        }
        return (
          <div className={css.main}>
              {main}
          </div>
        );
    }
};

module.exports = page;