import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './GoodsManage.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import TimeHelp from "../../../tool/TimeHelp";
import HttpTool from "../../../tool/HttpTool";
import {Modal} from "antd";

const ModalDelete = new ModalDel();

class GoodsList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getGoodList,
                otherParam: null,
            },
            search: {
                option: {
                    placeholder: '输入关键词搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        return true;
                    }
                }
            },
            new: {
                defaultValue: '创建商品',
                action: () => {
                    this.checkShopBefore()
                }
            }
        }
    }

    checkShopBefore(){
        HttpTool.post(APILXD.getShop,(code,msg,json)=>{
            if(json){
                this.props.openTab({
                    path: 'AddFoodItem',
                    title: '创建商品',
                    post: {
                        parent: this.props
                    }
                })
            }else{
                Modal.info({
                    title: "提示",
                    content: "您还没有填写店铺信息，请先完善店铺信息再添加商品。",
                    okText: "去填写",
                    onOk: ()=>{
                        this.props.openTab({
                            path: 'ShopDetail',
                            title: '我的店铺',
                            post: {
                                type: "edit",
                                parent: this.props
                            }
                        })
                    }
                })
            }
        },(code,msg)=>{
            Modal.error({
                title: "检查店铺信息出错",
                content: msg
            })
        },{})
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '价格(￥)',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: '创建时间',
                dataIndex: 'addtime',
                key: 'addtime',
                render:(text,record)=>{
                    return <div>{TimeHelp.getYMDHM(record.addtime - 5000,true)}</div>
                }
            },
            {
                title: '操作',
                width: 350,
                render: (text, record, index) => {
                    return (<div>
                        <div
                          className={less.btnEdit}
                          onClick={() => {
                              this.props.openTab({
                                  path: 'AddFoodItem',
                                  title: '编辑商品',
                                  post: {
                                      parent: this.props,
                                      id: record._id
                                  }
                              })
                          }}
                        >编辑
                        </div>
                        <div
                          className={less.btnDelete}
                          onClick={() => {
                              ModalDelete.show({
                                    title: "提示",
                                    okTitle: "确定",
                                    closeTitle: "取消",
                                },
                                {}
                                ,
                                {
                                    otherParam: {_id: record._id},
                                    content: record.name,
                                    url: APILXD.delGood,
                                    apiType: 'post',
                                    tip: '删除后将无法恢复，请谨慎操作',
                                    callBack: (state) => {
                                        //删除成功回调
                                        state === "success" && this._loadDataForNet();
                                    }
                                });
                          }}
                        >删除
                        </div>
                    </div>)
                }
            },
        ]
    }
}

module.exports = GoodsList;