import React, {useState, useEffect, useRef} from "react";
import less from "./ShopDetail.less";
import {Spin, Tabs, Modal, Result, Button, Steps, Form, Input, InputNumber, Radio, Select,TimePicker,Cascader,message,Descriptions,Tag,Badge,Space} from "antd";
import HttpTool from "../../../tool/HttpTool";
import APILXD from "../../../http/APILXD"
import {SmileOutlined} from '@ant-design/icons';
import AlbumSet from "./AlbumSet";
import UploadFile from "../../../components/upLoad/UploadFile";
import moment from "moment";

export default function ShopDetail(props) {
  console.log(props)
  // const [type, setType] = useState(props.post?.type)
  const [type, setType] = useState("see")
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [formData, setFormData] = useState(null)
  const [empty, setEmpty] = useState(false)
  const [merchantIndustry, setMerchantIndustry] = useState([])

  const baseInfoForm = useRef(null)
  const albumListForm = useRef(null)
  const coverMap = useRef({});

  useEffect(() => {
    getDetail()
    getMerchantIndustry()
  }, [])

  const getDetail = () => {
    setLoading(true)
    HttpTool.post(APILXD.getShop, (code, msg, json) => {

      setEmpty(!json)

      if(json){
        let formData = _.cloneDeep(json);
        formData.industrySet = [formData.industryId];
        if(formData.industryClassifyId){
          formData.industrySet.push(formData.industryClassifyId)
        }

        formData.starttime = moment("2023-11-20 "+ formData.starttime)
        formData.endtime = moment("2023-11-20 "+ formData.endtime)

        setFormData(formData)

        if(type==="edit"){
          baseInfoForm.current?.setFieldsValue(formData)
        }
      }

      if(json?.cover){
        coverMap.current[json.cover] = true;
      }

      setData(json)
      setLoading(false)
    }, (code, msg) => {
      Modal.error({
        title: "获取店铺详情出错",
        content: msg
      })
      setLoading(false)
    }, {})
  }

  const getMerchantIndustry = () => {
    HttpTool.post(APILXD.getMerchantIndustry, (code, msg, json) => {
      setMerchantIndustry(json)
    }, (code, msg) => {
      message.error(msg)
    }, {})
  }


  const dealSubmit=()=>{
    let [error,album,delImgs] = albumListForm?.current?.getAlbumList();

    if(error){
      message.warn(error)
      return;
    }

    baseInfoForm.current?.validateFields().then((values)=>{
      let params = _.cloneDeep(values);
      params.industryId = values.industrySet[0];
      params.industryClassifyId = values.industrySet[1] || null;
      delete params.industrySet;

      params.starttime = values.starttime.format("HH:mm");
      params.endtime = values.endtime.format("HH:mm");

      let delFiles = delImgs;
      Object.keys(coverMap.current)?.forEach((url)=>{
        if(url !== values.cover){
          delFiles.push(url)
        }
      })

      params.album = album || [];
      params.delFiles = delFiles;

      console.log(params)

      doSubmit(params)

    }).catch((errorInfo)=>{
      message.warn("请完善店铺信息")
      setCurrent(0)
    })
  }

  const doSubmit = (params)=>{
    setLoading(true)
    HttpTool.post(APILXD.editShop, (code, msg, json) => {
      message.success("编辑成功");
      setType("see");
      getDetail()
    }, (code, msg) => {
      message.error(msg)
    }, params)
  }

  const setShopStatus = (status)=>{
    setLoading(true)
    HttpTool.post(APILXD.setShopStatus, (code, msg, json) => {
      message.success(msg);
      getDetail()
    }, (code, msg) => {
      message.error(msg)
    }, {status})
  }

  return <div className={less.fullPage} style={{paddingTop: "20px"}}>
    {
      loading
        ? <div className={less.layerDiv}><Spin size={"large"}/></div>
        : null
    }
    {
      type === "edit"
        ? <div className={less.content}>
          <Steps
            style={{width: "50%", margin: "0 auto"}}
            current={current}
            size={"small"}
            items={[
              {
                title: "店铺信息",
              },
              {
                title: "店铺相册",
              }
            ]}
          />
          <div className={less.formContainerOuter}>
            <div className={current===0?less.formContainer:less.formContainerHide}>
              <Form
                ref={baseInfoForm}
                initialValues={formData}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 12 }}
              >
                <Form.Item
                  name={"title"}
                  label={"店铺名称"}
                  rules={[{required: true}]}
                >
                  <Input
                    maxLength={30}
                    placeholder="请填写店铺名称"
                  />
                </Form.Item>
                <Form.Item
                  name={"status"}
                  label={"店铺状态"}
                  rules={[{required: true}]}
                >
                  <Radio.Group>
                    <Radio value={1}>营业中</Radio>
                    <Radio value={2}>休息中</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name={"score"}
                  label={"评分"}
                  rules={[{required: true}]}
                >
                  <InputNumber
                    placeholder="请输入评分"
                    style={{width: "100%"}}
                    min={0}
                    max={5}
                    step={1}
                    precision={1}
                  />
                </Form.Item>
                <Form.Item
                  name={"priceAvg"}
                  label={"人均"}
                  rules={[{required: true}]}
                >
                  <InputNumber
                    placeholder="填写整数值，单位为元"
                    style={{width: "100%"}}
                    min={0}
                    max={10000000}
                    step={1}
                    precision={0}
                    prefix="￥"
                  />
                </Form.Item>
                <Form.Item
                  name={"industrySet"}
                  label={"店铺类型"}
                  rules={[{required: true}]}
                >
                  <Cascader
                    placeholder="请选择店铺类型"
                    showSearch={true}
                    changeOnSelect={true}
                    expandTrigger={"hover"}
                    fieldNames={{
                      label: "name",
                      value: "_id",
                      children: "classify",
                    }}
                    options={merchantIndustry}
                  />
                </Form.Item>
                <Form.Item
                  label={"营业时间"}
                >
                  <Form.Item
                    name={"starttime"}
                    label={"营业开始时间"}
                    noStyle={true}
                    rules={[{required: true}]}
                  >
                    <TimePicker
                      format={"HH:mm"}
                    />
                  </Form.Item>
                  &nbsp;-&nbsp;
                  <Form.Item
                    name={"endtime"}
                    label={"营业结束时间"}
                    noStyle={true}
                    rules={[{required: true}]}
                  >
                    <TimePicker
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  name={"tags"}
                  label={"设施与服务"}
                  rules={[{required: true}]}
                  normalize={(value, prevValue, prevValues) => {
                    let v = [];

                    if(value?.length > 5){
                      message.warn("最多设置5个标签")
                      v= value.slice(0,5)
                    }else{
                      v= value
                    }

                    if(v?.length){
                      v = v.filter((eachT)=>{
                        let ifOver = eachT?.length>5

                        if(ifOver){
                          message.warn("每个标签最多5个字符")
                        }

                        return !ifOver
                      })
                    }

                    return v;
                  }}
                >
                  <Select
                    allowClear={true}
                    mode="tags"
                    placeholder="请填写标签，每个标签不超过5个字"
                    maxTagTextLength={5}
                  />
                </Form.Item>
                <Form.Item
                  name={"mobile"}
                  label={"电话"}
                  rules={[{required: true}]}
                  normalize={(value, prevValue, prevValues) => {
                    return value?value.replace(/[^0-9\-]+/ig,""):null
                  }}
                >
                  <Input
                    placeholder="请填写电话"
                    maxLength={20}
                  />
                </Form.Item>
                <Form.Item
                  label={"地址"}
                  rules={[{required: true}]}
                  dependencies={["address"]}
                >
                  {({getFieldValue,setFieldValue})=>{
                    return <>
                      <Form.Item
                        name={"address"}
                        label={"地址"}
                        noStyle={true}
                        rules={[{required: true}]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 3, maxRows: 5 }}
                          placeholder="请填写地址"
                          maxLength={200}
                        />
                      </Form.Item>
                      <div
                        style={{textAlign: "right"}}
                      >
                        <Button
                          type={"link"}
                          onClick={()=>{
                            let address = getFieldValue("address")
                            if(!address){
                              message.warn("请先填写地址再获取经纬度")
                              return;
                            }
                            console.log(address)
                            HttpTool.post(APILXD.getGeoCode, (code, msg, json) => {
                              console.log(json)
                              setFieldValue("lng",json.lng)
                              setFieldValue("lat",json.lat)
                              message.success(msg)
                            }, (code, msg) => {
                              message.error(msg)
                            }, {address: address})
                          }}
                        >自动匹配经纬度</Button>
                      </div>
                    </>
                  }}
                </Form.Item>
                <Form.Item
                  name={"lng"}
                  label={"经度"}
                  rules={[{required: true}]}
                >
                  <InputNumber
                    placeholder="请输入经度"
                    style={{width: "100%"}}
                    min={-180}
                    max={180}
                    step={1}
                    precision={6}
                  />
                </Form.Item>
                <Form.Item
                  name={"lat"}
                  label={"纬度"}
                  rules={[{required: true}]}
                >
                  <InputNumber
                    placeholder="请输入纬度"
                    style={{width: "100%"}}
                    min={-90}
                    max={90}
                    step={1}
                    precision={6}
                  />
                </Form.Item>
                <Form.Item
                  label={"封面"}
                  name={"cover"}
                  rules={[{required: true}]}
                  normalize={(value, prevValue, prevValues) => {
                    if(value?.length){
                      coverMap.current[value[0].url] = true;
                      return value[0].url
                    }
                    return null
                  }}
                >
                  <UploadFile
                    key={formData?.cover}
                    bigLabel={true}
                    accept={"image/*"}
                    data={{
                      option: {
                        size: 1,
                        defaultValue: formData?.cover? [{url: formData?.cover}] : null
                      }
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
            <div className={current===1?less.formContainer:less.formContainerHide}>
              <AlbumSet
                key={"as_" + current + formData?.album?.length}
                ref={albumListForm}
                value={formData?.album}
              />
            </div>
          </div>
          <div className={less.footer}>
            <Button
              onClick={() => {
                setType("see")
                setCurrent(0)
              }}
            >
              取消
            </Button>
            {
              current === 1
                ? <Button
                  type={"primary"}
                  ghost={true}
                  onClick={() => {
                    setCurrent(0)
                  }}
                >
                  上一步
                </Button>
                : null
            }
            {
              current === 0
                ? <Button
                  type={"primary"}
                  ghost={true}
                  onClick={() => {
                    baseInfoForm.current?.validateFields().then((values)=>{
                      setCurrent(1)
                    }).catch((errorInfo)=>{

                    })
                  }}
                >
                  下一步
                </Button>
                : <Button
                  type={"primary"}
                  onClick={() => {
                    dealSubmit()
                  }}
                >
                  完成
                </Button>
            }
          </div>
        </div>
        : (
          empty
            ? <Result
              style={{marginTop: "200px"}}
              icon={<SmileOutlined/>}
              title="您还没有填写过店铺信息"
              extra={<Button
                type="primary"
                onClick={() => {
                  setType("edit")
                }}
              >去填写</Button>}
            />
            : <div className={less.fullPage}>
              <Tabs
                centered={true}
                tabBarExtraContent={<Button
                  type={"primary"}
                  onClick={()=>{
                    setCurrent(0)
                    setType("edit")
                  }}
                >编辑</Button>}
                items={[
                  {
                    label: "店铺信息",
                    key: "baseInfo",
                    children: <div className={less.fullPage} style={{width: "600px", margin: "0 auto", overflow: "auto"}}>
                      <Descriptions
                        bordered
                        column={1}
                        labelStyle={{width: "150px", textAlign: "right"}}
                        size={"small"}
                      >
                        <Descriptions.Item label="店铺名称">{data?.title}</Descriptions.Item>
                        <Descriptions.Item label="状态">
                          <div className={less.flexRow}>
                            {data?.status===1
                              ?<Badge status="success" text="营业中" />
                              :<Badge status="default" text="休息中" />
                            }
                            <Button
                              type={"link"}
                              onClick={()=>{
                                Modal.confirm({
                                  title: "提示",
                                  content: <Space>是否切换到
                                    {
                                      data?.status===1
                                        ?<span style={{color: "darkorange"}}>休息</span>
                                        :<span style={{color: "limegreen"}}>营业</span>
                                    }
                                    模式？
                                  </Space>,
                                  onOk: ()=>{
                                    setShopStatus(data?.status===1?2:1)
                                  }
                                })
                              }}
                            >切换</Button>
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="评分">{data?.score}</Descriptions.Item>
                        <Descriptions.Item label="人均">{data?.priceAvg} ￥</Descriptions.Item>
                        <Descriptions.Item label="店铺类型">{data?.industryName} / {data?.industryClassifyName}</Descriptions.Item>
                        <Descriptions.Item label="营业时间">{data?.starttime} - {data?.endtime}</Descriptions.Item>
                        <Descriptions.Item label="设置与服务">{data?.tags?.map((txt)=>{
                          return <Tag key={txt} color={"blue"}>{txt}</Tag>
                        })}</Descriptions.Item>
                        <Descriptions.Item label="电话">{data?.mobile}</Descriptions.Item>
                        <Descriptions.Item label="地址">{data?.address}</Descriptions.Item>
                        <Descriptions.Item label="经度">{data?.lng}</Descriptions.Item>
                        <Descriptions.Item label="纬度">{data?.lat}</Descriptions.Item>
                        <Descriptions.Item label="封面">
                          <img src={data?.cover} alt="封面" width={"100%"} style={{border: "1px solid #eee"}}
                               onClick={()=>{
                                 Modal.info({
                                   maskClosable: true,
                                   closable: true,
                                   width: "70%",
                                   icon: null,
                                   title: null,
                                   content: <div>
                                     <img src={data?.cover} alt="" style={{width: "100%"}}/>
                                   </div>
                                 })
                               }}
                          />
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  },
                  {
                    label: "店铺相册",
                    key: "album",
                    children: <div className={less.fullPage} style={{width: "600px", margin: "0 auto", overflow: "auto"}}>
                      {
                        data?.album?.map((each,index)=>{
                          return <Descriptions
                            key={"e_" + index}
                            bordered
                            column={1}
                            title={"相册类别：" + each.name}
                            labelStyle={{width: "50px", textAlign: "right"}}
                            size={"small"}
                          >
                            <Descriptions.Item label="图片">
                              {
                                each.imgs?.map((url)=>{
                                  return <div className={less.albImgBox}
                                    style={{backgroundImage: `url(${url})`}}
                                    onClick={()=>{
                                      Modal.info({
                                        maskClosable: true,
                                        closable: true,
                                        width: "70%",
                                        title: "相册图片",
                                        content: <div>
                                          <img src={url} alt="" style={{width: "100%"}}/>
                                        </div>
                                      })
                                    }}
                                  >

                                  </div>
                                })
                              }
                            </Descriptions.Item>
                          </Descriptions>
                        })
                      }
                    </div>
                  }
                ]}
              />
            </div>
        )

    }
  </div>
}